/**
 * This component displays the folder and interview grid grades and related files
 */
import React, { createElement, useEffect, useRef, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  RouteComponentProps,
  useHistory,
  useParams,
  withRouter,
  Link
} from 'react-router-dom';
import Return from '../../../../assets/images/junia-icon-fleche-3.png';
import './Grades.scss';
import { Contact } from '../../../../services/api/Contact/interface';
import FolderGrade from './components/FolderGrade/FolderGrade';
import InterviewGrade from './components/InterviewGrade/InterviewGrade';
import { List, ListItem, ListItemText } from '@mui/material';
import { me } from '../../../../services/api/Contact/interface';
import { EducationHistory } from '../../../../services/api/EducationHistory/interface';
import JuryService, {
  ApplicationsResponse
} from '../../../../services/api/Jury/Jury';
import Spinner from '../../../../utils/Spinner/Spinner';
import AuthService from '../../../../services/api/Auth/AuthService';
import Alert from '../../../../utils/Alert/Alert';
import FileService from '../../../../services/api/Files/FilesService';
import { PieceComplementaire } from '../../../../services/api/Files/interface';
import { saveAs } from 'file-saver';
import moment from 'moment';
import Constants from '../../../../constants/global';

import SpinnerComponent from '../../../../components/SpinnerComponent/SpinnerComponent';
import Modal from '../../../../components/Modal/Modal';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentDocumentService from '../../../../services/api/ContentDocument/ContentDocumentService';
import { ContentDocument } from '../../../../services/api/ContentDocument/interface';

interface ContainerProps {
  useContact: Contact;
}

type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const Grades: React.FC<IProps> = (props) => {
  const { t, useContact } = props;

  const { id } = useParams<{ id: string }>();
  const [candidature, setCandidature] = useState<ApplicationsResponse>();
  const [files, setFiles] = useState<PieceComplementaire[]>();
  const [me, setMe] = useState<me>();
  const [loading, setLoading] = useState<boolean>(false);
  const [bacData, setBacData] = useState<EducationHistory>();
  const [spinner, setSpinner] = useState({ files: true });
  const [file, setFile] = useState<any>();
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>({});
  const [contentDocument, setContentDocument] = useState<ContentDocument[]>();
  const [contact, setContact] = useState<Contact>(useContact);
  const [liste, setListe] = useState<any[]>([
    {
      name: 'folder',
      component: FolderGrade
    },
    {
      name: 'interview',
      component: InterviewGrade
    }
  ]);

  const history = useHistory();
  const divRef = useRef<HTMLDivElement>(null);

  Spinner.spinner$.subscribe((s) => {
    if (s !== loading) {
      setLoading(s);
    }
  });

  useEffect(() => {
    getMe();
  }, []);

  useEffect(() => {
    if (me?.id_heroku__c) {
      getApplication();
    }
  }, [me]);

  useEffect(() => {
    setFiles(getFiles());
    if (candidature?.Contact?.EducationHistorys) {
      findBac(candidature?.Contact?.EducationHistorys);
    }
    if (
      !!candidature?.Files?.some((f) => f && !f?.b64source) ||
      !!candidature?.Contact?.Files?.some((f) => f && !f?.b64source)
    ) {
      DownloadImages();
    }
    getContentDocument();
  }, [candidature]);

  const openModal = (data: any) => {
    setFile(data);
    setModalIsOpen(true);
  };

  const handleModalClose = async (): Promise<void> => {
    setFile(undefined);
    setModalIsOpen(false);
  };

  const dl = async (file?: PieceComplementaire): Promise<string> => {
    return (await FileService.downloadFile(file?.id_heroku__c))?.data;
  };

  const DownloadImages = async (): Promise<void> => {
    setSpinner({ files: false });
    for (let i = 0; i < (candidature?.Files?.length || 0); i++) {
      if (candidature?.Files?.length) {
        candidature.Files[i].b64source = await dl(candidature?.Files[i] || {});
      }
    }
    for (let i = 0; i < (candidature?.Contact?.Files?.length || 0); i++) {
      if (candidature?.Contact?.Files?.length) {
        candidature.Contact.Files[i].b64source = await dl(
          candidature?.Contact?.Files[i] || {}
        );
      }
    }
    setFiles(getFiles());
    setSpinner({ files: true });
  };

  const findBac = (educationHistory: EducationHistory[]) => {
    const bac = educationHistory.find(
      (education: EducationHistory) =>
        education.type_de_diplome__c === 'Baccalauréat'
    );
    if (bac) {
      setBacData(bac);
    }
  };

  const getMe = async (): Promise<void> => {
    try {
      Spinner.setSpinner(true);
      setMe((await AuthService.me())?.data);
    } catch (err) {
      Spinner.setSpinner(false);
      if (err instanceof Error) {
        Alert.setAlert({
          type: 'error',
          duration: 3000,
          message: err.message,
          open: true
        });
        history.goBack();
      }
    }
  };

  const getApplication = async (): Promise<void> => {
    try {
      setCandidature(
        (await JuryService.getApplication(me?.id_heroku__c, id))?.data
      );
      Spinner.setSpinner(false);
    } catch (err) {
      Spinner.setSpinner(false);
      if (err instanceof Error) {
        Alert.setAlert({
          type: 'error',
          duration: 3000,
          message: err.message,
          open: true
        });
      }
      history.goBack();
    }
  };

  useEffect(() => {
    let item = liste[0];
    setSelected(item);
  }, []);

  const goBack = () => {
    return history.goBack();
  };

  const handleClick = (item: any) => {
    setSelected(item);
  };

  const str2bytes = (str: any) => {
    let bytes = new Uint8Array(str.length);
    for (let i = 0; i < str.length; i++) {
      bytes[i] = str.charCodeAt(i);
    }
    return bytes;
  };

  const DownloadZIP = async (): Promise<void> => {
    try {
      Spinner.setSpinner(true);
      const zip = await FileService.downloadZip(candidature?.id_heroku__c);
      let blob = new Blob([str2bytes(zip.data)], { type: 'application/zip' });
      const fileName = candidature?.Contact?.firstname + '-' + candidature?.Contact?.lastname + '_' + candidature?.name + '.zip';
      saveAs(blob, fileName);
      Spinner.setSpinner(false);
    } catch (err) {
      Spinner.setSpinner(false);
      if (err instanceof Error) {
        Alert.setAlert({
          type: 'error',
          duration: 3000,
          message: err.message,
          open: true
        });
      }
    }
  };

  const getFiles = () => {
    const files = [...(candidature?.Contact?.Files || [])];
    candidature?.Files?.forEach((f) => {
      if (files.some((fi) => fi.name === f)) files.push(f);
    });
    return files;
  };

  const getContentDocument = () => {
    if (candidature?.Contact?.sfid) {
      ContentDocumentService.getContentDocument(
        candidature?.Contact?.sfid
      ).then((res) => {
        if (res.data.records.length > 0) {
          for (let i = 0; i < res.data.records.length; i++) {
            ContentDocumentService.getContentVersion(
              res.data.records[i].ContentDocument.Id
            ).then((resultat) => {
              setContentDocument((prevState) => {
                return [
                  ...(prevState || []),
                  {
                    id: res.data.records[i].ContentDocument.Id,
                    name:
                      res.data.records[i].ContentDocument.Title +
                      '.' +
                      res.data.records[i].ContentDocument.FileExtension,
                    extension:
                      res.data.records[i].ContentDocument.FileExtension,
                    b64source: resultat.data
                  }
                ];
              });
            });
          }
        }
      });
    }
    return contentDocument;
  };

  return (
    <div>
      {!loading && (
        <div className="grades general-container">
          <button className="grade-return" onClick={goBack}>
            <div className="return-icon">
              <img alt="retrun" src={Return} />
            </div>
            <span>Retour au dossier de candidature</span>
          </button>
          <div className="grade-content">
            <div className="grade-left">
              <div className="attachment-list">
                <div className="jury-switch">
                  <List className="switch-menu">
                    {liste.map((item) => (
                      <ListItem
                        button={false}
                        key={item.name}
                        onClick={() => handleClick(item)}
                        className={selected == item ? 'active' : ''}>
                        <ListItemText
                          className="tab-item"
                          primary={t(item.name)}
                        />
                      </ListItem>
                    ))}
                  </List>
                </div>
                <div className="attachment-header">
                  <h5>{t('attachments')}</h5>
                  <button className="btn btn-outlined" onClick={DownloadZIP}>
                    <span>{t('download')}</span>
                  </button>
                </div>
                <hr></hr>
                <div className="attachement-content">
                  <div className="attachement-container" ref={divRef}>
                    <div className="parcours student">
                      <div className="inline-field">
                        <h6> {t('gender')} </h6>
                        <p>
                          {candidature?.Contact?.hed__gender__c === 'Male'
                            ? t('male')
                            : candidature?.Contact?.hed__gender__c === 'Female'
                            ? t('female')
                            : t('nonBinary')}
                        </p>
                      </div>
                      <div className="inline-field">
                        <h6> {t('firstname')} </h6>
                        <p>{candidature?.Contact?.firstname}</p>
                      </div>
                      <div className="inline-field">
                        <h6> {t('lastname')} </h6>
                        <p>{candidature?.Contact?.lastname}</p>
                      </div>
                      <div className="inline-field">
                        <h6> {t('birthdate')} </h6>
                        <p>
                          {moment(candidature?.Contact?.birthdate).format(
                            'DD/MM/YYYY'
                          )}
                        </p>
                      </div>
                      <div className="inline-field">
                        <h6>{t('citizenship')}</h6>
                        <p>{candidature?.Contact?.hed__citizenship__c}</p>
                      </div>
                      {candidature?.Contact?.hed__dual_citizenship__c && (
                        <div className="inline-field">
                          <h6>{t('doubleCitizenship')}</h6>
                          <p>
                            {candidature?.Contact?.hed__dual_citizenship__c}
                          </p>
                        </div>
                      )}
                      <div className="inline-field">
                        <h6>{t('indicative')}</h6>
                        <p>
                          {
                            candidature?.Contact
                              ?.indicatif_pays_t_l_phone_mobile__c
                          }
                        </p>
                      </div>
                      <div className="inline-field">
                        <h6>{t('phone')}</h6>
                        <p>{candidature?.Contact?.mobilephone}</p>
                      </div>
                      <div className="inline-field">
                        <h6>{t('email')}</h6>
                        <p>{candidature?.Contact?.email}</p>
                      </div>
                      <div className="inline-field">
                        <h6>{t('programChoice')}</h6>
                        <p>{candidature?.Formation?.nom_formation_tech__c}</p>
                      </div>
                      <div className="inline-field">
                        <h6>{t('candidateComment')}</h6>
                        <p>{candidature?.commentaire_candidat__c}</p>
                      </div>
                      <div className="inline-field">
                        <h6>{t('partnerEstablishment')}</h6>
                        <p>
                          {candidature?.Contact?.etablissement_partenaire__c
                            ? 'Oui'
                            : 'Non'}
                        </p>
                      </div>
                    </div>
                    <hr></hr>
                    {candidature?.Contact?.type_tech__c === 'Echange' && (
                      <>
                        <div className="univ origine echange">
                          <h5>{t('homeUniversity')}</h5>
                          <div className="inline-field">
                            <h6>{t('homeUniversityName')}</h6>
                            <p>{candidature?.Contact?.nom_de_l_universit_d_origine_nouveau__c?.name}</p>
                          </div>
                          <div className="inline-field">
                            <h6>{t('homeUniversityAddress')}</h6>
                            <p>{candidature?.Contact?.adresse_de_l_universit__c}</p>
                          </div>
                          <div className="inline-field">
                            <h6>{t('erasmusCode')}</h6>
                            <p>{candidature?.Contact?.code_erasmus__c}</p>
                          </div>
                          <div className="inline-field">
                            <h6>{t('institutionalCoordinatorFullName')}</h6>
                            <p>{candidature?.Contact?.nom_du_coordinateur_institutionnel__c} {candidature?.Contact?.pr_nom_du_coordinateur_institutionnel__c}</p>
                          </div>
                          <div className="inline-field">
                            <h6>{t('institutionalCoordinatorEmail')}</h6>
                            <p>{candidature?.Contact?.email_coordinateur_institutionnel__c}</p>
                          </div>
                          <div className="inline-field">
                            <h6>{t('institutionalCoordinatorPhoneNumber')}</h6>
                            <p>{candidature?.Contact?.indicatif_t_l_phone_coordinateur_instit__c} {candidature?.Contact?.telephone_coordinateur_institutionnel__c}</p>
                          </div>
                        </div>
                        <hr></hr>
                      </>
                    )}
                    {candidature?.Contact?.type_tech__c === 'Echange' && (
                      <>
                        <div className="parcours echange">
                          <h5>{t('programChoice')}</h5>
                          <div className="inline-field">
                            <h6>{t('programm')}</h6>
                            <p>{candidature?.Contact?.programme__c}</p>
                          </div>
                          <div className="inline-field">
                            <h6>{t('juniaSpecialty')}</h6>
                            <p>{candidature?.sp_cialit_principale_junia__c}</p>
                          </div>
                          <div className="inline-field">
                            <h6>{t('desiredDuration')}</h6>
                            <p>{candidature?.duree_du_sejour_souhaite__c}</p>
                          </div>
                          <div className="inline-field">
                            <h6>{t('startPeriod')}</h6>
                            <p>{candidature?.p_riode_de_d_but__c}</p>
                          </div>
                          <div className="inline-field">
                            <h6>{t('universityName')}</h6>
                            <p>{candidature?.nom_universit_d_origine__c}</p>
                          </div>
                          <div className="inline-field">
                            <h6>{t('collegeDepartment')}</h6>
                            <p>{candidature?.facult_d_partement__c}</p>
                          </div>
                          <div className="inline-field">
                            <h6>{t('coordinatorLastname')}</h6>
                            <p>
                              {candidature?.nom_coordinateur_institutionnel__c}
                            </p>
                          </div>
                          <div className="inline-field">
                            <h6>{t('coordinatorFirstname')}</h6>
                            <p>
                              {
                                candidature?.pr_nom_coordinateur_institutionnel__c
                              }
                            </p>
                          </div>
                          <h6>{t('coordinatorMail')}</h6>
                          {/* NON PRESENT DANS SF */}
                          {/* <p>{candidature?.facult_d_partement__c}</p> */}
                        </div>
                        <hr></hr>
                      </>
                    )}
                    {candidature?.Contact?.type_tech__c === 'Francais' && (
                      <>
                        <div className="parcours bac">
                          <h5>{t('bac')}</h5>
                          <div className="inline-field">
                            <h6>{t('bacType')}</h6>
                            <p>{bacData?.type_de_bac__c}</p>
                          </div>
                          <div className="inline-field">
                            <h6>{t('bacOption')}</h6>
                            <p>{bacData?.option_du_bac__c}</p>
                          </div>
                          {bacData?.specialites_du_bac__c && (
                            <div className="inline-field">
                              <h6>{t('specialty')}</h6>
                              <p>{bacData?.specialites_du_bac__c}</p>
                            </div>
                          )}
                          <div className="inline-field">
                            <h6>{t('bacMention')}</h6>
                            <p>{bacData?.mention_du_bac__c}</p>
                          </div>
                          <div className="inline-field">
                            <h6>{t('graduationYear')}</h6>
                            <p>{bacData?.annee_d_obtention_du_bac__c}</p>
                          </div>
                        </div>
                        <hr></hr>
                      </>
                    )}
                    <div className="parcours sup">
                      <h5>{t('higherEducation')}</h5>
                      {candidature?.Contact?.EducationHistorys?.filter(
                        (history) =>
                          history.type_de_diplome__c !== 'Baccalauréat'
                      ).map((history, index, row) => {
                        return (
                          <>
                            {candidature?.Contact?.type_tech__c ===
                              'Francais' && (
                              <>
                                <p>
                                  {t('otherDiploma')} {index + 1}
                                </p>
                                <div className="inline-field">
                                  <h6>{t('schoolYear')}</h6>
                                  <p>{history.annee_scolaire__c}</p>
                                </div>
                                <div className="inline-field">
                                  <h6>{t('formationName')}</h6>
                                  <p>{history.intitule_de_formation__c}</p>
                                </div>
                                <div className="inline-field">
                                  <h6>{t('level')}</h6>
                                  <p>{history.niveau__c}</p>
                                </div>
                                <div className="inline-field">
                                  <h6>{t('speDom')}</h6>
                                  <p>{history.specialite_domaine_fr__c}</p>
                                </div>
                                <div className="inline-field">
                                  <h6>{t('appFollow')}</h6>
                                  <p>
                                    {
                                      history.formation_suivie_par_apprentissage__c
                                    }
                                  </p>
                                </div>
                                <div className="inline-field">
                                  <h6>{t('institution')}</h6>
                                  <p>
                                    {
                                      history.hed__educational_institution_name__c
                                    }
                                  </p>
                                </div>
                                {history.ville_etablissement_fr__c && (
                                  <div className="inline-field">
                                    <h6>{t('city')}</h6>
                                    <p>{history.ville_etablissement_fr__c}</p>
                                  </div>
                                )}
                                {index + 1 !== row.length && (
                                  <hr className="small-break-line"></hr>
                                )}
                              </>
                            )}
                            {candidature?.Contact?.type_tech__c ===
                              'International' && (
                              <>
                                <div className="inline-field">
                                  <h6>{t('schoolYear')}</h6>
                                  <p>{history.annee_scolaire__c}</p>
                                </div>
                                {history.hed__degree_earned__c !== 'Autre' ? (
                                  <div className="inline-field">
                                    <h6>{t('diploma')}</h6>
                                    <p>{history.hed__degree_earned__c}</p>
                                  </div>
                                ) : (
                                  <div className="inline-field">
                                    <h6>{t('otherDiploma')}</h6>
                                    <p>{history.autre_diplome__c}</p>
                                  </div>
                                )}
                                <div className="inline-field">
                                  <h6>{t('speDom')}</h6>
                                  <p>{history.specialite__c}</p>
                                </div>
                                <div className="inline-field">
                                  <h6>{t('yearLevel')}</h6>
                                  <p>{history.annee_niveau__c}</p>
                                </div>
                                <div className="inline-field">
                                  <h6>{t('institution')}</h6>
                                  <p>
                                    {
                                      history.hed__educational_institution_name__c
                                    }
                                  </p>
                                </div>
                                <div className="inline-field">
                                  <h6>{t('average')}</h6>
                                  <p>{history.moyenne__c}</p>
                                </div>
                                <div className="inline-field">
                                  <h6>{t('mentionRank')}</h6>
                                  <p>{history.classement__c}</p>
                                </div>
                              </>
                            )}
                          </>
                        );
                      })}
                    </div>
                    <hr></hr>
                    <div className="parcours langue">
                      <h5>{t('langSkill')}</h5>
                      {candidature?.Contact?.ContactLanguages?.map(
                        (language, index, row) => {
                          return (
                            <>
                              {language.langue1__c === 'Anglais' ? (
                                <>
                                  <p>{t('english')}</p>
                                  <div className="inline-field">
                                    <h6>{t('level')}</h6>
                                    <p>{language.hed__fluency__c}</p>
                                  </div>
                                  <div className="inline-field">
                                    <h6>{t('englishCertif')}</h6>
                                    <p>
                                      {
                                        language.certificat_officiel_de_langue__c
                                      }
                                    </p>
                                  </div>
                                  <div className="inline-field">
                                    <h6>{t('graduationYear')}</h6>
                                    <p>{language.ann_e_d_obtention__c}</p>
                                  </div>
                                  <div className="inline-field">
                                    <h6>{t('score')}</h6>
                                    <p>{language.score__c}</p>
                                  </div>
                                </>
                              ) : (
                                <>
                                  {candidature?.Contact?.type_tech__c ===
                                    'International' &&
                                    language.langue1__c === 'Français' && (
                                      <>
                                        <p>{t('french')}</p>
                                        <div className="inline-field">
                                          <h6>{t('level')}</h6>
                                          <p>
                                            {
                                              candidature?.Contact
                                                ?.type_d_exp_rience__c
                                            }
                                          </p>
                                        </div>
                                        <div className="inline-field">
                                          <h6>{t('frenchCertif')}</h6>
                                          <p>
                                            {
                                              language.certificat_officiel_de_langue__c
                                            }
                                          </p>
                                        </div>
                                        <div className="inline-field">
                                          <h6>{t('graduationYear')}</h6>
                                          <p>{language.ann_e_d_obtention__c}</p>
                                        </div>
                                        <div className="inline-field">
                                          <h6>{t('score')}</h6>
                                          <p>{language.score__c}</p>
                                        </div>
                                      </>
                                    )}
                                  {language.langue1__c !== 'Anglais' &&
                                    language.langue1__c !== 'Français' && (
                                      <>
                                        <p>{t('otherLanguages')}</p>
                                        <div className="inline-field">
                                          <h6>{t('language')}</h6>
                                          <p>{language.Languages?.name}</p>
                                        </div>
                                        <div className="inline-field">
                                          <h6>{t('level')}</h6>
                                          <p>{language.hed__fluency__c}</p>
                                        </div>
                                      </>
                                    )}
                                </>
                              )}
                              {index + 1 !== row.length && (
                                <hr className="small-break-line"></hr>
                              )}
                            </>
                          );
                        }
                      )}
                    </div>
                    <hr></hr>
                    {candidature?.Contact?.ExperienceProfessionnelle && (
                      <>
                        <div className="parcours cursus">
                          <h5>{t('proExperiences')}</h5>
                          {candidature?.Contact?.ExperienceProfessionnelle?.map(
                            (exp, index, row) => {
                              return (
                                <>
                                  <p>
                                    {t('experienceNumber')} {index + 1}
                                  </p>
                                  <div className="inline-field">
                                    <h6>{t('type')}</h6>
                                    <p>{exp.type_d_exp_rience__c}</p>
                                  </div>
                                  <div className="inline-field">
                                    <h6>{t('year')}</h6>
                                    <p>{exp.annee_new__c}</p>
                                  </div>
                                  <div className="inline-field">
                                    <h6>{t('duration')}</h6>
                                    <p>{exp.duree__c}</p>
                                  </div>
                                  <div className="inline-field">
                                    <h6>{t('employerName')}</h6>
                                    <p>{exp.employeur__c}</p>
                                  </div>
                                  {candidature?.Contact?.type_tech__c ===
                                    'Francais' && (
                                    <div className="inline-field">
                                      <h6>{t('city')}</h6>
                                      <p>{exp.ville__c}</p>
                                    </div>
                                  )}
                                  <div className="inline-field">
                                    <h6>{t('country')}</h6>
                                    <p>{exp.pays__c}</p>
                                  </div>
                                  <div className="inline-field">
                                    <h6>{t('mission')}</h6>
                                    <p>{exp.mission__c}</p>
                                  </div>
                                  {index + 1 !== row.length && (
                                    <hr className="small-break-line"></hr>
                                  )}
                                </>
                              );
                            }
                          )}
                        </div>
                      </>
                    )}
                    <hr></hr>
                    <div className="parcours trips">
                      <h5>{t('foreignExperience')}</h5>

                      {candidature?.Contact?.SejourEtrangers?.map(
                        (trip, index, row) => {
                          return (
                            <>
                              <p>
                                {t('tripNumber')} {index + 1}
                              </p>
                              <div className="inline-field">
                                <h6>{t('year')}</h6>
                                <p>{trip.ann_e__c}</p>
                              </div>
                              <div className="inline-field">
                                <h6>{t('duration')}</h6>
                                <p>{trip.duree__c}</p>
                              </div>
                              <div className="inline-field">
                                <h6>{t('country')}</h6>
                                <p>{trip.pays__c}</p>
                              </div>
                              <div className="inline-field">
                                <h6>{t('stayPurpose')}</h6>
                                <p>{trip.objet__c}</p>
                              </div>
                              {index + 1 !== row.length && (
                                <hr className="small-break-line"></hr>
                              )}
                            </>
                          );
                        }
                      )}
                    </div>
                    <hr></hr>
                    {/* DISPLAY FILES */}
                    <h5>{t('candidateFiles')}</h5>
                    <div className="attached-files">
                      {!spinner.files ? (
                        <SpinnerComponent backdrop={false} />
                      ) : files?.length ? (
                        files.map((file) => {
                          return (
                            <div className="arrows">
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="label"
                                onClick={() => openModal(file)}>
                                <VisibilityIcon />
                              </IconButton>
                              <Link
                                to={`/jury/file/${file.id_heroku__c}/${file?.name}`}>
                                {file?.name} / {file?.type__c}
                              </Link>
                            </div>
                          );
                        })
                      ) : (
                        <i>{t('noAttachments')}</i>
                      )}
                    </div>
                    {/* END DISPLAY FILES */}
                    <hr></hr>
                    {/* DISPLAY FILES */}
                    <h5>{t('otherFiles')}</h5>
                    <div className="attached-files">
                      {!spinner.files ? (
                        <SpinnerComponent backdrop={false} />
                      ) : contentDocument ? (
                        contentDocument.map((file) => {
                          return (
                            <div className="arrows">
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="label"
                                onClick={() => openModal(file)}>
                                <VisibilityIcon />
                              </IconButton>
                              <Link
                                to={`/jury/content_document/${file?.id}/${file?.name}/${file?.extension}`}>
                                {file?.name}
                              </Link>
                            </div>
                          );
                        })
                      ) : (
                        <i>{t('noAttachments')}</i>
                      )}
                    </div>
                    {/* END DISPLAY FILES */}
                  </div>
                </div>
              </div>
            </div>
            <div className="grade-right">
              <>{console.log('SELECTED COMPONENT' + selected.name)}
              </>
              {selected.component && (
                <React.Fragment>
                  {createElement(selected.component, {
                    useContact: contact,
                    candidature,
                    me,
                    handleChange: (candidature: ApplicationsResponse) => {}
                  })}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      )}
      <Modal
        title="Aperçu"
        classe="display-docs"
        modalIsOpen={modalIsOpen}
        closeModal={() => handleModalClose()}>
        {Constants.IMG_EXT.includes(file?.name?.split('.')?.pop() ?? '') ? (
          <>
            <img
              className="embed-file"
              src={file?.b64source}
              alt="fail to load"
            />
          </>
        ) : (
          <></>
        )}
        {Constants.DOC_EXT.includes(file?.name?.split('.')?.pop() ?? '') ? (
          <>
            <iframe
              className="embed-file"
              title={file?.name}
              src={file?.b64source}></iframe>
          </>
        ) : (
          <></>
        )}
      </Modal>
    </div>
  );
};

export default withRouter(
  withTranslation(['jury', 'contact', 'form', 'common'])(Grades)
);
