/**
 * This component contains logic to displays application form components 
 */
import React, { createElement, useEffect, useState } from 'react';
import './CandidatureProcess.scss';
import { useHistory, withRouter } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, useParams} from 'react-router-dom';
import MissingElement from './components/MissingElement/MissingElement';
import Candidature from './components/Candidature/Candidature';
import Recapitulatif from './components/Recapitulatif/Recapitulatif';
import Payment from './components/Payment/Payment';
import ProceedPayment from './components/ProceedPayment/ProceedPayment';
import ValidePayment from './components/ValidePayment/ValidePayment';
import _ from 'lodash';
import { Application_hed } from '../../services/api/Candidature/interface';
import CandidatureService from '../../services/api/Candidature/CandidatureService';
import { me } from '../../services/api/Contact/interface';
import ContactService from '../../services/api/Contact/ContactService';
import { Contact } from '../../services/api/Contact/interface';
import AuthService from '../../services/api/Auth/AuthService';
import { FileToUpload } from '../../services/api/Files/interface';
import PaymentService from '../../services/api/Payment/PaymentService';
import Spinner from '../../utils/Spinner/Spinner';
import Exchange from './components/Exchange/Exchange';
import { EducationHistory } from '../../services/api/EducationHistory/interface';
interface ContainerProps {}
type ChildProps = {};

interface IProps
  extends ContainerProps,
    ChildProps,
    WithTranslation,
    RouteComponentProps {}

const CandidatureProcess: React.FC<IProps> = (props) => {
  const { t } = props;
  const history = useHistory();
  useEffect(() => {}, []);
  const [applicationForm, setApplicationForm] = useState<Application_hed>({});
  const [me, setMe] = useState<me>({});
  const [contact, setContact] = useState<Contact>({});
  const [selectedState, setSelectedState] = useState<any>();
  const [paymentStatus, setPaymentStatus] = useState<string>('');
  const [displayComponent, setDisplayComponent] = useState<boolean>(false)

  let { formation, candidate_id } = useParams<{ formation: string, candidate_id: string }>();
  useEffect(() => {
    if (!formation) {
      let path = candidate_id ? '/agent/candidats' : '/home';
      history.push(path);
    } else {
      getMe();
    }
  }, []);
  useEffect(() => {
    if (me.id_heroku__c && formation) {
      getContact();
    }
  }, [me]);
  useEffect(() => {
    if (contact.id_heroku__c && formation) {
      getformation();
    }
  }, [contact.id_heroku__c]);
  useEffect(() => {
    if (applicationForm && applicationForm.id_heroku__c) {
      // checkPaymentStatus();
      setStep(applicationForm);
    }
  }, [applicationForm.id_heroku__c]);

  const getMe = async () => {
    let meResponse = await AuthService.me();
    setMe(meResponse.data);
  };
  const getContact = async () => {
    try {
      let contactId = candidate_id ? candidate_id : me.id_heroku__c;
      if (contactId) {
        let contactResponse = await ContactService.getContact(contactId);
        setContact(contactResponse.data);
      } 
    } catch (error) {
      console.log(
        '🚀 ~ file: CreateAccount.tsx ~ line 65 ~ getContact ~ error',
        error
      );
    }
  };

  const getformation = async () => {
    try {
      setDisplayComponent(false)
      let formationResponse;
      let contactId = candidate_id ? candidate_id : me.id_heroku__c;
      if (contactId) {
        formationResponse = await CandidatureService.getCandidature(
          contactId,
          formation
        );
        if (
          formationResponse?.data.id_heroku__c &&
          formationResponse?.data.statut__c === 'Brouillon'
          
        ) {
          setDisplayComponent(true)
          setApplicationForm(formationResponse.data);
        } else {
          let path = candidate_id ? '/agent/candidats' : '/application';
          history.push(path);
        }
      }  
    } catch (error) {
      console.log(
        '🚀 ~ file: CandidatureProcess.tsx ~ line 42 ~ getformation ~ error',
        error
      );
    }
  };

  const componentList: any = {
    Francais: [
      {
        name: 'MissingElement',
        status: ['Non commencée', 'Pièces communes'],
        statusName: 'Pièces communes',
        component: MissingElement,
        skipIfPayment: false
      },
      {
        name: 'Candidature',
        status: ['Pièces spécifiques'],
        statusName: 'Pièces spécifiques',
        component: Candidature,
        skipIfPayment: false
      },
      {
        name: 'Recapitulatif',
        status: ['Récapitulatif'],
        statusName: 'Récapitulatif',
        component: Recapitulatif,
        skipIfPayment: false
      },
      {
        name: 'Payment',
        status: ['Paiement'],
        statusName: 'Paiement',
        component: Payment,
        skipIfPayment: true
      },
      {
        name: 'ProceedPayment',
        status: ['Paiement process'],
        statusName: 'Paiement process',
        component: ProceedPayment,
        skipIfPayment: true
      },
      {
        name: 'ValidePayment',
        status: ['Paiement validé'],
        statusName: 'Paiement validé',
        component: ValidePayment,
        skipIfPayment: true
      }
    ],
    International: [
      {
        name: 'MissingElement',
        status: ['Non commencée', 'Pièces communes'],
        statusName: 'Pièces communes',
        component: MissingElement,
        skipIfPayment: false
      },
      {
        name: 'Candidature',
        status: ['Pièces spécifiques'],
        statusName: 'Pièces spécifiques',
        component: Candidature,
        skipIfPayment: false
      },
      {
        name: 'Recapitulatif',
        status: ['Récapitulatif'],
        statusName: 'Récapitulatif',
        component: Recapitulatif,
        skipIfPayment: false
      },
      {
        name: 'Payment',
        status: ['Paiement'],
        statusName: 'Paiement',
        component: Payment,
        skipIfPayment: true
      },
      {
        name: 'ProceedPayment',
        status: ['Paiement process'],
        statusName: 'Paiement process',
        component: ProceedPayment,
        skipIfPayment: true
      },
      {
        name: 'ValidePayment',
        status: ['Paiement validé'],
        statusName: 'Paiement validé',
        component: ValidePayment,
        skipIfPayment: true
      },
    ],
    Echange: [
      {
        name: 'MissingElement',
        status: ['Non commencée', 'Pièces communes'],
        statusName: 'Pièces communes',
        component: MissingElement,
        skipIfPayment: false
      },
      {
        name: 'Exchange',
        status: ['Echange'],
        statusName: 'Echange',
        component: Exchange,
        skipIfPayment: false
      },
      {
        name: 'Candidature',
        status: ['Pièces spécifiques'],
        statusName: 'Pièces spécifiques',
        component: Candidature,
        skipIfPayment: false
      },
      {
        name: 'Recapitulatif',
        status: ['Récapitulatif'],
        statusName: 'Récapitulatif',
        component: Recapitulatif,
        skipIfPayment: false
      }   ,  {
        name: 'Payment',
        status: ['Paiement'],
        statusName: 'Paiement',
        component: Payment,
        skipIfPayment: true
      },
    ]
  };
  // const [selectedStep, setSelectedStep] = useState(
  //   componentList['Francais'][0]
  // );

  const handleNext = () => {
    let candidateTypeTech = candidate_id ? contact.type_tech__c : me.type_tech__c;
    let candidateId = candidate_id ? candidate_id : me.id_heroku__c;
    if (candidateTypeTech) {
      let found = _.findIndex(
        componentList[candidateTypeTech],
        (o: any) => o.name === selectedState.name
        );
      if (candidateId)
        // CandidatureService.patchCandidature(me.id_heroku__c, applicationForm);
        setSelectedState(componentList[candidateTypeTech][found + 1]);
    }
  };

  const findIsJunia = (list: EducationHistory[]) => {
    let found = _.find(
      list,
      (education) =>
        education.intitule_de_formation__c === 'Je suis déjà étudiant JUNIA'
    );
    return found;
  };

  const setStep = async (data: Application_hed) => {
    let candidateTypeTech = candidate_id ? contact.type_tech__c : me.type_tech__c;
    let contactId = candidate_id ? candidate_id : me.id_heroku__c;
    if (candidateTypeTech) {
      let found = _.find(componentList[candidateTypeTech], (c) =>
        c.status.includes(data?.candidature_statut_tech__c || 'Non commencée')
      );
      let skip;
      if (found?.skipIfPayment) {
        let status = await checkPaymentStatus();
        let isJunia;
        if (contact.EducationHistorys) {
          isJunia = findIsJunia(contact.EducationHistorys);
        }
        if (
          status === 'already_paid' ||
          contact.boursier__c === 'Oui' ||
          contact.type_tech__c === 'Echange' ||
          isJunia ||
          contact.etablissement_partenaire__c ||
          contact.agent__c ||
          (applicationForm.Formation &&
            applicationForm.Formation.type_de_formation__c ===
              'Formation France apprenti')
        ) {
          if (contactId) {
            let cand = await CandidatureService.patchCandidature(
              contactId,
              {
                ...applicationForm,
                candidature_statut_tech__c: 'Validée',
                statut__c: 'Dépôt candidature'
              },
              [],
              ['motivation', 'documents', 'recommendation', 'plan']
            );
            let path = candidate_id ? '/agent/applications' : '/application';
            history.push(path);
            return false;
          }
        }
      }
      // if( )
      setSelectedState(found);
    }
  };
  const handlePrevious = () => {
    let candidateTypeTech = candidate_id ? contact.type_tech__c : me.type_tech__c;
    if (candidateTypeTech) {
      let found = _.findIndex(
        componentList[candidateTypeTech],
        (o: any) => o.name === selectedState.name
      );
      let newIndex = found - 1;
      if (newIndex >= 0) {
        setSelectedState(componentList[candidateTypeTech][newIndex]);
      } else {
        history.push({ pathname: '/home', state: { catalogue: true } });
      }
    }
  };

  useEffect(() => {
    if (
      me.id_heroku__c &&
      formation &&
      selectedState &&
      selectedState.name !== 'ValidePayment'
    ) {
      getformation();
    }
  }, [selectedState]);

  const checkPaymentStatus = async () => {
    try {
      let paymentResponse = await PaymentService.status();
      setPaymentStatus(paymentResponse.data);
      return paymentResponse.data;
    } catch (error) {
      console.log(
        '🚀 ~ file: CandidatureProcess.tsx ~ line 160 ~ checkPaymentStatus ~ error',
        error
      );
    }
  };

  const handleChange = async (
    application: Application_hed,
    step: number,
    files?: FileToUpload[]
  ) => {
    // Spinner.setSpinner(true);
    let candidateTypeTech = candidate_id ? contact.type_tech__c : me.type_tech__c;
    let contactId = candidate_id ? candidate_id : me.id_heroku__c;
    if (candidateTypeTech) {
      let newStatus: string;
      let found = _.findIndex(
        componentList[candidateTypeTech],
        (c: any) => c.name === selectedState.name
      );
      newStatus = componentList[candidateTypeTech][found + step]
        ? componentList[candidateTypeTech][found + step].statusName
        : 'Non commencée';

      let skip;

      if (
        componentList[candidateTypeTech][found + step]?.skipIfPayment ||
        componentList[candidateTypeTech][found]?.skipIfPayment
        ) {
          Spinner.setSpinner(true);
          let status = await checkPaymentStatus();
          let isJunia;
          if (contact.EducationHistorys) {
            isJunia = findIsJunia(contact.EducationHistorys);
          }
        if (
          status === 'already_paid' ||
          contact.boursier__c === 'Oui' ||
          contact.type_tech__c === 'Echange' ||
          isJunia ||
          contact.etablissement_partenaire__c ||
          contact.agent__c ||
          (applicationForm.Formation &&
            applicationForm.Formation.type_de_formation__c ===
              'Formation France apprenti')
        ) {
          if (contactId) {
            let cand = await CandidatureService.patchCandidature(
              contactId,
              {
                ...applicationForm,
                candidature_statut_tech__c: 'Validée',
                statut__c: 'Dépôt candidature'
              },
              files,
              ['motivation', 'documents', 'recommendation', 'poursuite', 'plan']
            );
            if (candidate_id) {
              history.push('/agent/applications');
            }
            Spinner.setSpinner(false);
            if (step > 0) {
              handleNext();
            } else {
              handlePrevious();
            }
            // history.push({ pathname: '/application' });

            return false;
          }
        } else {
          Spinner.setSpinner(false);
        }
      }
      // if( )
      // setSelectedState(found);
      if (contactId && newStatus !== 'Paiement validé') {
        await CandidatureService.patchCandidature(
          contactId,
          {
            ...application,
            candidature_statut_tech__c: newStatus
          },
          files,
          ['motivation', 'documents', 'recommendation', 'poursuite', 'plan']
        );
        if (step > 0) {
          handleNext();
        } else {
          handlePrevious();
        }
      } else {
        if (contactId) {
          if (step > 0) {
            handleNext();
          } else {
            handlePrevious();
          }
        }
      }
    }
  };
  return (
    <div className="home">
      {selectedState &&
        selectedState.component &&
        displayComponent  &&
        createElement(selectedState.component, {
          application: applicationForm,
          next: handleNext,
          previous: handlePrevious,
          onStep: handleChange,
          applicationData: applicationForm,
          me: me,
          getApplication: getformation
        })}
    </div>
  );
};

export default withRouter(
  withTranslation(['CandidatureProcess', 'common'])(CandidatureProcess)
);
